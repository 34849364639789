import * as React from "react";
import { MenuItemLink } from "react-admin";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CachedIcon from "@material-ui/icons/Cached";
import DescriptionIcon from "@material-ui/icons/Description";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import BusinessIcon from "@material-ui/icons/Business";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import Email from "@material-ui/icons/Email";
import CryptoJS from "crypto-js";
import Auth from "@cadent/ulp-auth";
import { getRole,getRolesFromAuth} from "../../authProvider/authProvider";

const Menu = () => {
  const roles =
    window.CADENT_SSO_V2 === "true"
      ? getRolesFromAuth(Auth.userData)
      : localStorage.getItem("app")
      ? JSON.parse(
          CryptoJS.AES.decrypt(
            localStorage.getItem("app"),
            process.env.REACT_APP_APPLICATION_ID
          ).toString(CryptoJS.enc.Utf8)
        ).roles
      : null;

  const role = getRole(roles);

  return (
    <div style={{ marginTop: "24px" }}>
      {roles && (
        <>
          {!["ar-user", "brs-user"].includes(role) && (
            <MenuItemLink
              to="/job"
              primaryText="Jobs"
              leftIcon={<DashboardIcon/>}
            />
          )}
          {role !== "brs-user" && (
            <MenuItemLink
              to="/ar"
              primaryText="AR"
              leftIcon={<DashboardIcon/>}
            />
          )}
          {role !== "ar-user" && (
            <MenuItemLink
              to="/brs"
              primaryText="BRS"
              leftIcon={<DashboardIcon/>}
            />
          )}
          {!["ar-user", "brs-user"].includes(role) && (
            <>
              <MenuItemLink
                to="/transcoder"
                primaryText="Transcoders"
                leftIcon={<CachedIcon/>}
              />
              <MenuItemLink
                to="/format"
                primaryText="Formats"
                leftIcon={<DescriptionIcon/>}
              />
              <MenuItemLink
                to="/location"
                primaryText="Locations"
                leftIcon={<LocationOnIcon/>}
              />
              <MenuItemLink
                to="/destination"
                primaryText="Destinations"
                leftIcon={<BusinessIcon/>}
              />
              <MenuItemLink
                  to="/emailtemplate"
                  primaryText="Email Templates"
                  leftIcon={<Email/>}
              />
            </>
          )}
          {role !== "ar-user" && (
            <MenuItemLink
              to="/recipient"
              primaryText="Recipients"
              leftIcon={<ContactMailIcon/>}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Menu;
