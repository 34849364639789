import React, {useCallback, useMemo} from 'react'
import isHotkey from 'is-hotkey'
import {Editable, withReact, useSlate, Slate} from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Node,
  Range,
} from 'slate'
import {withHistory} from 'slate-history'
import {Card, IconButton} from "@material-ui/core";
import {useForm} from "react-final-form";
import {jsx} from 'slate-hyperscript'
import {slateToHtml, htmlToSlate,payloadSlateToHtmlConfig} from '@slate-serializers/html'
import isUrl from 'is-url'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['ul', 'li','ol', 'h1', 'h2', 'h3', 'h4', 'link']
const TEXT_ALIGN_TYPES = ['left', 'align_center', 'right', 'justify']


const ELEMENT_TAGS = {
  A: el => ({type: 'link', url: el.getAttribute('href')}),
  BLOCKQUOTE: () => ({type: 'quote'}),
  H1: () => ({type: 'h1'}),
  H2: () => ({type: 'h2'}),
  H3: () => ({type: 'h3'}),
  H4: () => ({type: 'h4'}),
  LI: () => ({type: 'li'}),
  OL: () => ({type: 'ol'}),
  P: () => ({type: 'paragraph'}),
  SPAN: () => ({type: 'span'}),
  UL: () => ({type: 'ul'}),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({code: true}),
  DEL: () => ({strikethrough: true}),
  EM: () => ({italic: true}),
  I: () => ({italic: true}),
  S: () => ({strikethrough: true}),
  STRONG: () => ({bold: true}),
  U: () => ({underline: true}),
}

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const {nodeName} = el
  let parent = el

  if (
      nodeName === 'PRE' &&
      el.childNodes[0] &&
      el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes).map(deserialize).flat()

  if (children.length === 0) {
    children = [{text: ''}]
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }
  console.log(nodeName,"NODE NAME")
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map(child => jsx('text', attrs, child))
  }
  console.log("CACA",children)
  return children
}

export const EmailEditor = (props) => {
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(
      () => withInlines(withHtml(withReact(withHistory(createEditor())))),
      []
  )
  const form = useForm()
  const initialValue = props.isEdit ? htmlToSlate(props.body) : [
          {
            children: [{ text: props.isEdit ? props.record.body :'Create your Email Template' }],
          },
      ]
  const onChangeBody = (body) => {
    form.change('body', slateToHtml(body))
  }
  return (
      <Slate editor={editor} initialValue={initialValue} onChange={(value) => onChangeBody(value)}>
        <Card>
          <MarkButton format="bold" icon="format_bold"/>
          <MarkButton format="italic" icon="format_italic"/>
          <MarkButton format="underline" icon="format_underlined"/>
          <AddLinkButton icon="add_link"/>
          <RemoveLinkButton icon="link_off"/>
          <BlockButton format="h1" icon="looks_one"/>
          <BlockButton format="h2" icon="looks_two"/>
          <BlockButton format="h3" icon="looks_3"/>
          <BlockButton format="h4" icon="looks_4"/>
          <BlockButton format="ul" icon="format_list_numbered" />
          <BlockButton format="ol" icon="format_list_bulleted" />
        </Card>
        <Editable className="editor" style={{overflow: "scroll", height: "500px", overflowX: "hidden", outline: "0px"}}
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  placeholder="Create your Email Template"
                  spellCheck
                  autoFocus
                  onKeyDown={event => {
                    for (const hotkey in HOTKEYS) {
                      if (isHotkey(hotkey, event)) {
                        event.preventDefault()
                        const mark = HOTKEYS[hotkey]
                        toggleMark(editor, mark)
                      }
                    }
                  }}
        />
      </Slate>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? 'li' : format,
    }
  }
  console.log(newProperties,isActive,isList,SlateElement)
  Transforms.setNodes(editor, newProperties)
  if (!isActive && isList) {
    const block = {type: format, children: []}
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const {selection} = editor
  if (!selection) return false

  const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            n[blockType] === format,
      })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const withHtml = editor => {
  const {insertData, isInline, isVoid} = editor
  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const html = data.getData('text/html')
    if (html) {
      const fragment = htmlToSlate(html)
      Transforms.insertFragment(editor, fragment)
      return
    }

    insertData(data)
  }

  return editor
}
const Element = ({attributes, children, element}) => {
  const style = {textAlign: element.align}
  switch (element.type) {
    case 'block-quote':
      return (
          <blockquote style={style} {...attributes}>
            {children}
          </blockquote>
      )
    case 'bulleted-list':
      return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
      )
    case 'h1':
      return (
          <h1 style={style} {...attributes}>
            {children}
          </h1>
      )
    case 'h2':
      return (
          <h2 style={style} {...attributes}>
            {children}
          </h2>
      )
    case 'h3':
      return (
          <h3 style={style} {...attributes}>
            {children}
          </h3>
      )
    case 'h4':
      return (
          <h4 style={style} {...attributes}>
            {children}
          </h4>
      )
    case 'li':
      return (
          <li style={style} {...attributes}>
            {children}
          </li>
      )
    case 'ul':
      return (
          <ol style={style} {...attributes}>
            {children}
          </ol>
      )
    case 'bulleted-list':
      return (
          <ul style={style} {...attributes}>
            {children}
          </ul>
      )
    case 'p':
      return <p style={style} {...attributes}>{children}</p>
    case 'link':
      return (
          <a style={style} href={element.url} {...attributes}>
            {children}
          </a>
      )
    default:
      return <span {...attributes}>{children}</span>
  }
}

const Leaf = ({attributes, children, leaf}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({format, icon}) => {
  const editor = useSlate()
  return (
      <IconButton
          active={isBlockActive(
              editor,
              format,
              TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
          )}
          onMouseDown={event => {
            event.preventDefault()
            toggleBlock(editor, format)
          }}
      >
      <span className="material-symbols-outlined">
          {icon}
        </span>
      </IconButton>
  )
}

const MarkButton = ({format, icon}) => {
  const editor = useSlate()
  return (
      <IconButton
          active={isMarkActive(editor, format)}
          onMouseDown={event => {
            event.preventDefault()
            toggleMark(editor, format)
          }}
      >
        <span className="material-symbols-outlined">
          {icon}
        </span>
      </IconButton>
  )
}

const withInlines = editor => {
  const {insertData, insertText, isInline, isElementReadOnly, isSelectable} =
      editor

  editor.isInline = element =>
      ['link', 'button', 'badge'].includes(element.type) || isInline(element)

  editor.isElementReadOnly = element =>
      element.type === 'badge' || isElementReadOnly(element)

  editor.isSelectable = element =>
      element.type !== 'badge' && isSelectable(element)

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}
const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, {
    match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}


const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, {
    match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}


const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const {selection} = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{text: url}] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, {split: true})
    Transforms.collapse(editor, {edge: 'end'})
  }
}


const AddLinkButton = ({format, icon}) => {
  const editor = useSlate()
  return (
      <IconButton
          active={isLinkActive(editor)}
          onMouseDown={event => {
            event.preventDefault()
            const url = window.prompt('Enter the URL of the link:')
            if (!url) return
            insertLink(editor, url)
          }}
      >
 <span className="material-symbols-outlined">
          {icon}
        </span> </IconButton>
  )
}

const RemoveLinkButton = ({format, icon}) => {
  const editor = useSlate()
  return (
      <IconButton
          active={isLinkActive(editor)}
          onMouseDown={event => {
            if (isLinkActive(editor)) {
              unwrapLink(editor)
            }
          }}
      >
 <span className="material-symbols-outlined">
          {icon}
        </span> </IconButton>
  )
}

