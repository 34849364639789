import {Card, makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  ListContextProvider, Loading,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
  useQuery
} from "react-admin";
import { StepperFieldJobBrs } from "../../components";
import { buttonStyle } from "../../style";
import debounce from "lodash/debounce";
import keyBy from "lodash/keyBy";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "100%",
  },
  paddingLeft: {
    paddingLeft: "14px",
  },
}));

const BrsFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="request_id,broadcastrequests#isci@_ilike" alwaysOn/>
    </Filter>
  );
};

export const BrsListActions = (props) => {
  const classes = buttonStyle();
  return (
      <TopToolbar style={{marginLeft:"auto"}}>
        <CreateButton
            className={classes.button}
            label="New BRS Job"
            basePath="brs"
        />
      </TopToolbar>
  );
};

const buildFilterQuery = (filterValues,page,perPage,currentSort) => {
  return {
    type: "getList",
    resource: "brsjob",
    payload: {
      pagination: {page, perPage},
      sort: currentSort,
      filter:{...filterValues}
    }
  }
};

export const BrsList = (props) => {
  const classes = useStyles();
  const [brsData,setBrsData] = useState([])
  const [brsIds, setBrsIds] = useState([])

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({field: "created", order: "DESC"});
  const [eventSource, setEventSource] = useState(null)
  const [filterValues, setFilterValues] = useState({"request_id":''})

  const {data,total} = useQuery(buildFilterQuery(filterValues,page,perPage,currentSort),
      {
        onFailure: (error) => {
          console.error(error);
        }
      });

  // useEffect(() => {
  //   if (eventSource) {
  //     eventSource.close()
  //   }
  //   const evtSource = new EventSource(`${window.REACT_APP_API_URL}/subscribe/brs?sort_order=${currentSort.order}&sort_field=${currentSort.field}&per_page=${perPage}&page=${page}`)
  //   setEventSource(evtSource)
  //   evtSource.addEventListener("new_event", function (event) {
  //     const newData = JSON.parse(event.data)
  //     setBrsData(keyBy(newData, 'id'))
  //     setBrsIds(newData.map(({id}) => id))
  //   });
  //   evtSource.addEventListener("end_event", function (event) {
  //     evtSource.close();
  //   });
  //
  //   return () => {
  //     evtSource.close();
  //   };
  // }, [currentSort, perPage,page]);

  useEffect(() => {
    if(data){
      setBrsData(keyBy(data, 'id'))
      setBrsIds(data.map(({id}) => id))
    }
  }, [data])

  const debouncedHandleFilterChange = debounce((value) => {
    setFilterValues(value);
  }, 700);


  return brsData ? (
      <ListContextProvider value={{
        data: brsData,
        ids: brsIds,
        total,
        filterValues:filterValues,
        setFilters: debouncedHandleFilterChange,
        page,
        perPage,
        setPage,
        setPerPage,
        setSort: (field, order) => setSort({field, order}),
        currentSort,
        resource:props.resource,
        selectedIds: [],
        basePath:"brs"
      }}>
        <div style={{display:"flex"}}><BrsFilter/><BrsListActions /></div>
        <Card>
        <Datagrid rowClick={(id, basePath, record) => `brs/${id}/show`}>
        <TextField source="request_id" label="ID" />
        <DateField source="created" label="Date Created" showTime />
        <TextField source="estimate_id"/>
        <StepperFieldJobBrs
          label={
            <div className={classes.label}>
              <span>Preflight</span>
              <span className={classes.paddingLeft}>Media</span>
              <span>Notifications</span>
            </div>
          }
        ></StepperFieldJobBrs>
      </Datagrid>
          <Pagination
              rowsPerPageOptions={[25, 50, 100, 250, 500]}
          />
        </Card>
    </ListContextProvider>
  )  : (
      <Loading/>
  );
};
