import {makeStyles} from "@material-ui/core/styles";
import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {
  ArrayField,
  Datagrid,
  ChipField ,
  TextField,
  FunctionField,
  Loading,
  useQuery, Pagination,
} from "react-admin";
import keyBy from "lodash/keyBy";
import CircularProgress from "@material-ui/core/CircularProgress";
import WarningIcon from "@material-ui/icons/Warning";
import {Cancel as CancelIcon, CheckCircle as CheckCircleIcon} from "@material-ui/icons";


const useStyles = makeStyles({
  drawerPaper: {
    width: "20% !important",
  },
  tooltip: {
    fontSize: "14px",
  },
  table: {
    width: "60%",
    marginLeft: "auto",
    display: "flow"
  },
  rowCell: {
    borderBottom: "0px",
  },
  headerCell: {
    borderBottom: "0px"
  },
});


export const  BroadcastRequestList = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [currentSort, setSort] = useState({field: "updated", order: "DESC"});
  const [broadcast, setBroadcast] = useState(null)
  const [eventSource, setEventSource] = useState(null)
  console.log(props)

  const {data, total, loading} = useQuery(
      {
        type: "getList",
        resource: "broadcastrequest",
        payload: {
          pagination: {page, perPage},
          sort: currentSort,
          filter: {
            brs_job_id:props.brs_job.id,
          }
        },
      },
      {
        onFailure: (error) => {
          console.error(error);
        }
      });


  // useEffect(() => {
  //   if (eventSource) {
  //     eventSource.close()
  //   }
  //   const evtSource = new EventSource(`${window.REACT_APP_API_URL}/subscribe/brs/${props.brs_job.id}/broadcastrequest?sort_order=${currentSort.order}&sort_field=${currentSort.field}&per_page=${perPage}&page=${page}`)
  //   setEventSource(evtSource)
  //   evtSource.addEventListener("new_event", function (event) {
  //     setBroadcast(JSON.parse(event.data))
  //   });
  //
  //   evtSource.addEventListener("end_event", function (event) {
  //     evtSource.close();
  //   });
  //
  //   return () => {
  //     evtSource.close();
  //   };
  // }, [currentSort,perPage,page]);

  useEffect(() => {
    setBroadcast(data)
  }, [data])

  if (loading) {
    return <Loading/>;
  }
 console.log(broadcast)
  return props.brs_job && broadcast ? (
      <Fragment>
        <Datagrid data={keyBy(broadcast, 'id')}
                  ids={broadcast.map(({id}) => id)}
                  currentSort={currentSort}
                  setSort={(field, order) => setSort({field, order})}
                  hasBulkActions={false}>
          <TextField source="brand"/>
          <TextField source="title"/>
          <FunctionField label="Master ISCIs" render={r => r.original_iscis.map(isci => <ChipField key={isci} record={{isci}} source="isci"/>)}/>
          <TextField source="isci" label="Copy ID"/>
          <TextField source="duration"/>
          <TextField source="sigma_encoding"/>
          <FunctionField label="Preflight" render={r => {
            if (!r.step_history) {
              return ""
            } else if (r.step_history["PREFLIGHT"] === 'IN_PROGRESS') {
              return <CircularProgress size={30}/>
            } else if (r.step_history["PREFLIGHT"] === 'CAUTION') {
              return <WarningIcon/>
            } else if (r.step_history["PREFLIGHT"] === 'COMPLETE') {
              return <CheckCircleIcon/>
            } else if (r.step_history["PREFLIGHT"] === "ERROR") {
              return <CancelIcon/>
            }
          }}/>
          <FunctionField label="Delivery" render={r => {
            if (!r.step_history) {
              return ""
            } else if (r.step_history["TRANSFERS"] === 'IN_PROGRESS') {
              return <CircularProgress size={30}/>
            } else if (r.step_history["TRANSFERS"] === 'CAUTION') {
              return <WarningIcon/>
            } else if (r.step_history["TRANSFERS"] === 'COMPLETE') {
              return <CheckCircleIcon/>
            } else if (r.step_history["TRANSFERS"] === "ERROR") {
              return <CancelIcon/>
            }
          }}/>
        </Datagrid>
        <Pagination
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            total={total}
            rowsPerPageOptions={[25, 50, 100, 250, 500]}
        />
      </Fragment>
  ) : (
      <Loading/>
  );
};
