import {Button, Card, CardContent, Divider, Drawer, IconButton, Tab, Tabs, Tooltip} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import {makeStyles} from "@material-ui/core/styles";
import keyBy from "lodash/keyBy";
import * as React from "react";
import {Fragment, useCallback, useEffect, useState} from "react";
import {
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  Loading,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useQuery,
  useRecordContext,
  useNotify, useUpdate, Pagination
} from "react-admin";
import {useHistory} from "react-router-dom";
import {StatusField, StatusTransferField, TextFieldWithIcon} from "../../components/index";
import {ProgressField} from "../../components/ProgressBar";
import {buttonStyle, createUpdateStyle} from "../../style";
import DetailsAuditLogs from "./DetailsAuditLogs";
import {
  triggerLocalPodWorkflow,
  triggerMediaJobWorkflow,
  triggerRetryBrs, triggerRetryPod,
  triggerRetryReel,
  triggerRetryTranscode,
  triggerRetryTransfer
} from "../../helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  PlayCircleFilled as PlayCircleFilled
} from "@material-ui/icons";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles({
  drawerPaper: {
    width: "20% !important",
  },
  tooltip: {
    fontSize: "14px",
  },
});

const getTabData = ({data, tabValue, jobType}) => {
  if (tabValue === "preflight") {
    if ((jobType === "reels" || jobType === "sareel") && data.reels) {
      return keyBy(data.reels, "id");
    }else if ((jobType === "localpod" || jobType === "nationalpod") && data.pods) {
      return keyBy(data.pods, "id");
    }
  }
  return keyBy(data, "id")
}

const getTabDataIds = ({data, tabValue, jobType}) => {
  if (tabValue === "preflight" && (jobType === "reels" || jobType === "sareel") && data.reels) {
    return data.reels.map(({id}) => id);
  } else if ((jobType === "localpod" || jobType === "nationalpod") && data.pods) {
    return data.pods.map(({id}) => id);
  }
  return data.map(({id}) => id);
}

const TabbedDatagrid = (props) => {
  const jobType = props.record.job_type;
  const initialTabValue = ["brs", "reel","sareel","localpod","nationalpod"].includes(jobType) ? "preflight" : "transcoders";
  const [tabValue, setTabValue] = useState(initialTabValue);
  const [totals] = useState({});
  const [page, setPage] = useState(1);
  const [perPage,setPerPage] = useState(100);
  const [currentSort, setSort] = useState({field: "created", order: "DESC"});
  const [isOpen, setIsOpen] = useState(null);
  const [auditLogId, setAuditLogId] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [isDelivering, setIsDelivering] = useState(false);
  const [clicked, setClicked] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const buttonClasses = buttonStyle();
  const notify = useNotify();
  const [update, { error }] = useUpdate();
  const [eventSource, setEventSource] = useState(null)
  const [jobData, setJobData] = useState([])
  const [jobIds, setJobIds] = useState([])
  const tabs = ["brs", "reel", "sareel", "localpod", "nationalpod" ].includes(jobType) ? [
    {id: "preflight", name: "PRE-FLIGHT STATUS"},
    {id: "transcoders", name: "Transcode Jobs"},
    {id: "formats", name: "Transfers"},
    {id: "auditlog", name: "Audit Log"},
  ] : [
    {id: "transcoders", name: "Transcode Jobs"},
    {id: "formats", name: "Transfers"},
    {id: "auditlog", name: "Audit Log"},
  ];

  useEffect(() => {
    if (tabValue === "auditlog") {
      setSort({field: "created", order: "DESC"});
    }
  }, [jobType, tabValue]);

  const resource = tabValue === "preflight"
      ? jobType === "brs" ? "broadcastrequest" : jobType === "localpod" || jobType === "nationalpod" ? "pod" : "reel"
      : tabValue === "transcoders"
          ? "deliveryformat"
          : tabValue === "formats"
              ? "deliveryrequest"
              : tabValue === "auditlog" && "auditlog"

  const {data, total, loading} = useQuery(
      {
        type: "getList",
        resource,
        payload: {
          pagination: {page, perPage},
          sort: currentSort,
          filter: resource === "broadcastrequest" ? {"brsjob#job_id@_eq": props.id} : {job_id: props.id},
        },
      },
      {
        onFailure: (error) => {
          console.error(error);
        }
      });


  useEffect(() => {
    if(data){
      setJobData(getTabData({data, tabValue, jobType}))
      setJobIds(getTabDataIds({data, tabValue, jobType}))
    }
  }, [data])

  const handleChange = useCallback(
      (event, value) => {
        setTabValue(value);
        setPage(1);
        setSort({field: "id", order: "ASC"});
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [tabValue]
  );

  const handleAuditLogsDetails = (id) => {
    setLoadingDetails(false);
    setAuditLogId(id);
    setIsOpen(true);
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const startDelivery = async (jobId, brsJobId, batchId) => {
    setIsDelivering(true)
    if (jobType === "brs"){
      await triggerMediaJobWorkflow(jobId, brsJobId)
    }else if(jobType === 'localpod'){
      await triggerLocalPodWorkflow(jobId,batchId)
    }
    notify(`Delivery in progress`, {type: 'info'});
  }

  const retryReel = async (jobType,jobId, reelId) => {
    await triggerRetryReel(jobType, jobId, reelId)
    notify(`Retry Reel in Progress`, {type: 'info'});
  }

  const retryPod = async (jobType,jobId, podId) => {
    await triggerRetryPod(jobType, jobId, podId)
    notify(`Retry Pod in Progress`, {type: 'info'});
  }

  const retryBrs = async (brsJobId, jobId,broadcastId) => {
    await triggerRetryBrs(brsJobId, jobId,broadcastId)
    notify(`Retry Reel in Progress`, {type: 'info'});
  }

  const retryTranscode = async (jobId,brsJobId, dfmtId, jobType) => {
    await triggerRetryTranscode(jobId,brsJobId, dfmtId, jobType)
    notify(`Retry Transcode in Progress`, {type: 'info'});
  }

  const retryTransfer = async(jobId, brsJobId, transferGroup, dfmtId,jobType) => {
    if(transferGroup.retries === 3){
      notify('Max Retry Transfer Limit Exceeded',{type:'warning'})
      return
    }
    update('transfergroup', transferGroup.id, {retries:transferGroup.retries + 1}, {retries:transferGroup.retries})
    if(error){
      notify('Retry Transfer Failed',{type:'warning'})
      return
    }
    await triggerRetryTransfer(jobId,brsJobId,transferGroup.id,dfmtId, jobType)
    notify('Retry Transfer in Progress',{type:'info'})
  }

  const getPreflightStatus = (jobData) =>{
    let preflightIsComplete = false
    const total = Object.keys(jobData).length
    let total_complete = 0
    if(jobData){
       Object.keys(jobData).forEach((key,index)=>{
         if (jobType === "localpod") {
           if(jobData[key]?.step_status === "PREFLIGHT_COMPLETE"){
             total_complete += 1
           }
         }else{
           if(jobData[key]?.step_status === "PREFLIGHT_COMPLETE"){
             total_complete += 1
           }
        }
      })
    }
    if(total_complete === total){
      preflightIsComplete = true
    }
    return preflightIsComplete
  }


  if (loading || jobData.length === 0) {
    return <Loading/>;
  }
  return  jobData ? (
      <Fragment>
        {
          <Tabs
              variant="fullWidth"
              centered
              value={tabValue}
              indicatorColor="primary"
              onChange={handleChange}
          >
            {tabs.map((choice) => (
                <Tab
                    key={choice.id}
                    label={
                      totals && totals[choice.id]
                          ? `${choice.name} (${totals[choice.id]})`
                          : choice.name
                    }
                    value={choice.id}
                />
            ))}
          </Tabs>
        }
        <Divider/>
        {
          <Card>
            <CardContent>
              <ListContextProvider
                  value={{
                    data: jobData,
                    ids: jobIds,
                    total,
                    page,
                    perPage,
                    setPage,
                    setPerPage,
                    setSort: (field, order) => setSort({field, order}),
                    currentSort,
                    resource,
                    selectedIds: [],
                  }}
              >
                {["brs", "reel",'sareel','nationalpod','localpod'].includes(jobType) &&
                    tabValue === "preflight" && (
                        jobType === "brs" ? (
                                <Datagrid>
                                  <TextField label="Request ID" source="brsjob.request_id"/>
                                  <TextField label="Estimate" source="brsjob.estimate_id"/>
                                  <TextField label="Copy ID (ISCI)" source="isci"/>
                                  <FunctionField label="Master ISCIs"
                                                 render={r => r.original_iscis && r.original_iscis.map(isci => <ChipField
                                                     key={isci} record={{isci}} source="isci"/>)}/>
                                  <FunctionField label="Completed" render={r => r?.step_status === "PREFLIGHT_IN_PROGRESS" ? <CircularProgress size={20} /> :  r?.step_status === "PREFLIGHT_CAUTION" ? <WarningIcon size={20} /> : r?.step_status === "PREFLIGHT_COMPLETE"  ? <CheckCircleIcon size={20} /> :  r?.step_status === "PREFLIGHT_ERROR" ? <CancelIcon size={20} /> : null} />
                                  <FunctionField render={(record) => {
                                        if (record?.step_status === "PREFLIGHT_ERROR") {
                                          return <IconButton disabled={clicked.includes(record.id)} style={{padding: 0}}
                                                             aria-label="retry" color="#3CE7EA"
                                                             onClick={() => {
                                                               retryBrs(record.brs_job_id, props.id, record.id);
                                                               setClicked((state) => {
                                                                 return [...state, record.id]
                                                               })
                                                             }}>
                                            <PlayCircleFilled/></IconButton>
                                        } else if (record?.step_status === "PREFLIGHT_IN_PROGRESS") {
                                          return <CircularProgress size={20}/>
                                        } else {
                                          return ""
                                        }
                                      }}
                                      label="Retry"
                                  />
                                </Datagrid>
                            ) :
                            (
                                <Datagrid>
                                  <TextFieldWithIcon
                                      sortable={true}
                                      source="house_isci"
                                      label="ISCI"
                                  />
                                  <FunctionField
                                      source="master_iscis"
                                      render={(record) => record?.master_iscis && record?.master_iscis.map(isci => {
                                        if (!record?.invalid_iscis) {
                                          return <span style={{
                                            fontSize: "14px",
                                            marginLeft: "4px",
                                            color: "#90caf9"
                                          }}>{isci}</span>
                                        } else if (record?.invalid_iscis.includes(isci)) {
                                          return <span style={{
                                            fontSize: "14px",
                                            marginLeft: "4px",
                                            color: "#8c8c8c"
                                          }}>{isci}</span>
                                        } else {
                                          return <span
                                              style={{fontSize: "14px", margin: "8px", color: "#90caf9"}}>{isci}</span>
                                        }
                                      }).reduce((prev, curr) => [prev, ' +', curr])}
                                      label={"Master ISCI(s)"}
                                  />
                                  {jobType === "localpod" ? <FunctionField label="Completed" render={r => r?.step_status === "PREFLIGHT_IN_PROGRESS" ? <CircularProgress size={20} /> :  r?.step_status === "PREFLIGHT_CAUTION" ? <WarningIcon size={20} /> : r?.step_status === "PREFLIGHT_COMPLETE"  ? <CheckCircleIcon size={20} /> :  r?.step_status === "PREFLIGHT_ERROR" ? <CancelIcon size={20} /> : null} /> : null}
                                  <FunctionField
                                      render={(record) => {
                                        if (record?.step_status === "PREFLIGHT_ERROR") {
                                          return <IconButton disabled={clicked.includes(record.id)} style={{padding: 0}}
                                                             aria-label="retry" color="#3CE7EA"
                                                             onClick={() => {
                                                               if (jobType.includes("reel")){
                                                                 retryReel(jobType,record.job_id, record.id);
                                                                 setClicked((state) => {
                                                                   return [...state, record.id]
                                                                 })
                                                               }else if(jobType.includes("pod")){
                                                                 retryPod(jobType,record.job_id, record.id);
                                                                 setClicked((state) => {
                                                                   return [...state, record.id]
                                                                 })
                                                               }
                                                             }}>
                                            <PlayCircleFilled/></IconButton>
                                        } else if (record?.step_status === "PREFLIGHT_IN_PROGRESS") {
                                          return <CircularProgress size={20}/>
                                        } else {
                                          return ""
                                        }
                                      }}
                                      label="Retry"
                                  />
                                </Datagrid>
                            )
                    )}
                {tabValue === "transcoders" ? (
                    <Datagrid>
                      <TextField sortable={true} source="isci" label="ISCI"/>
                      <ReferenceField source="format_id" reference="format">
                        <TextField source="name"/>
                      </ReferenceField>
                      <StatusField label="Status" source="status"></StatusField>
                      <ProgressField
                          sortable={false}
                          source="progress"
                          type="transcoder"
                      />
                      <FunctionField
                          render={(record) => {
                            if (record?.status === "ERROR") {
                              return <IconButton disabled={clicked.includes(record.id)} style={{padding: 0}}
                                                 aria-label="retry" color="#3CE7EA"
                                                 onClick={() => {
                                                   const brsJobId = jobType === "brs" ? props.record?.brs_job.id : ""
                                                   retryTranscode(record.job_id, brsJobId, record.id, jobType)
                                                   setClicked((state)=>{return [...state, record.id]})
                                                 }}>
                                <PlayCircleFilled/></IconButton>
                            } else if (record?.step_status === "IN_PROGRESS") {
                              return <CircularProgress size={20}/>
                            } else {
                              return ""
                            }
                          }}
                          label="Retry"
                      />
                    </Datagrid>
                ) : tabValue === "formats" ? (
                    <Datagrid>
                      <TextField source="call_letters" label="Destination"/>
                      <TextField source="isci" label="ISCI"/>
                      <TextField
                          source="destination.location.name"
                          label="Location"
                      />
                      <TextField source="destination.format.name" label="Format"/>
                      <StatusField source="status"/>
                      <ProgressField
                          sortable={false}
                          source="progress"
                          type="format"
                      /><FunctionField render={(record) => {
                      if (record?.status === "ERROR") {
                        return <IconButton disabled={clicked.includes(record.transfer_group_id) || record?.transfergroup?.retries === 3} style={{padding: 0}}
                                           aria-label="retry" color="#3CE7EA"
                                           onClick={() => {
                                             const brsJobId = jobType === "brs" ? props.record?.brs_job.id : ""
                                             retryTransfer(record.job_id,brsJobId,record?.transfergroup,record.format_id,jobType);
                                             setClicked((state) => {
                                               return [...state, record.transfer_group_id]
                                             })
                                           }}>
                          <PlayCircleFilled/></IconButton>
                      } else if (record?.status === "IN_PROGRESS") {
                        return <CircularProgress size={20}/>
                      } else {
                        return ""
                      }
                    }}
                                       label="Retry"
                    />
                    </Datagrid>
                ) : (
                    tabValue === "auditlog" && (
                        <>
                          <Datagrid
                              rowClick={(id) => {
                                handleAuditLogsDetails(id);
                              }}
                          >
                            <DateField source="created" label="Date Created" showTime/>
                            <TextField source="job.batch_id" label="BATCH ID"/>
                            <TextField source="action"/>
                            <TextField source="level"/>
                            <TextField source="detail" label="Details"/>
                          </Datagrid>
                          <Drawer
                              variant="persistent"
                              open={isOpen}
                              anchor="right"
                              onClose={handleClose}
                              classes={{
                                paper: classes.drawerPaper,
                              }}
                          >
                            {loadingDetails ? (
                                <Loading/>
                            ) : (
                                <DetailsAuditLogs
                                    id={auditLogId}
                                    onCancel={handleClose}
                                    resource={"auditlog"}
                                    basePath={"/auditlog"}
                                ></DetailsAuditLogs>
                            )}
                          </Drawer>
                        </>
                    )
                )}
                <Pagination
                    rowsPerPageOptions={[25, 50, 100, 250, 500]}
                />
              </ListContextProvider>
            </CardContent>
            {(jobType === "brs" || jobType === "localpod") && tabValue === "preflight" && (
                <CardActions style={{justifyContent: "flex-end"}}>
                  <Tooltip title="Start Delivery is disabled until the preflight is complete."
                           classes={{tooltip: classes.tooltip}}>
                    <Button variant="contained"
                            disabled={!props.record.pending && getPreflightStatus(jobData) && !isDelivering ? false : true}
                            onClick={() => startDelivery(props.record?.id, props.record.brs_job?.id, props.record.batch_id)}
                            className={buttonClasses.button}>
                      Start Delivery
                    </Button>
                  </Tooltip>
                </CardActions>
            )}
          </Card>
        }
      </Fragment>
  ) : (
      <Loading/>
  );
};

const JobDetailTitle = () => {
  const record = useRecordContext();
  return <span>Job Detail {record.job_type === "brs" ? "BRS" : record.job_type === "nationalpod" ? "National POD" : record.job_type === "localpod" ? "Local POD" : record.job_type === "sareel" ? "SA Reel" : record.job_type === "reel" ? "Reel" : "Batch"}: { record.job_type === "brs" ? record.brs_job.request_id :record.batch_id}</span>;
};

export const JobShow = (props) => {
  const classes = createUpdateStyle();

  return (
      <div className={classes.card} style={{width: "100%"}}>
        <a className={classes.breadcrumbLink} href="#/job">
          {"< Jobs"}
        </a>
        <Show
            style={{marginTop: "24px"}}
            title={<JobDetailTitle/>}
            {...props}
        >
          <SimpleShowLayout>
            <TabbedDatagrid {...props} />
          </SimpleShowLayout>
        </Show>
      </div>
  );
};
