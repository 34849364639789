import CryptoJS from "crypto-js";
import moment from "moment";
import Auth from "@cadent/ulp-auth";

export const getRolesFromAuth = (user)=>{
  if(!user){
    return null
  }
  return user.user_data.Auth.find(auth => auth.ApplicationId === process.env.REACT_APP_APPLICATION_ID).Roles
}
export const getRole = (roles) => {
  if (!roles) {
    return null;
  } else if (roles.includes("aviary-admin")) {
    return "aviary-admin";
  } else if (roles.includes("dh-admin")) {
    return "dh-admin";
  } else if (roles.includes("dh-user")) {
    return "dh-user";
  } else if (roles.includes("ar-user") && !roles.includes("brs-user")) {
    return "ar-user";
  }else if(roles.includes("ar-user") && roles.includes("brs-user")){
    return "brs-user"
  }else{
    return "no-role"
  }
};


export const authProvider = {
  // called when the user attempts to log in
  login: ({ expires, ssoComplete, roles }) => {
    if (ssoComplete) {
      const app = CryptoJS.AES.encrypt(
        JSON.stringify({ expires, ssoComplete, roles }),
        process.env.REACT_APP_APPLICATION_ID
      ).toString();
      localStorage.setItem("app", app);
    }
    return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    if (window.CADENT_SSO_V2 === "true") {
      Auth.logout();
      return Promise.resolve();
    }
    localStorage.removeItem("app");
    localStorage.removeItem("roles");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      if (window.CADENT_SSO_V2 === "true") {
        Auth.logout();
        return Promise.reject();
      }
      localStorage.removeItem("app");
      localStorage.removeItem("roles");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    if (window.CADENT_SSO_V2 === "true") {
      let token = Auth.getToken();
      let roles = getRolesFromAuth(Auth.userData)
      return token && roles && ((roles.includes("ar-user")) ||
      (roles && roles.includes("aviary-admin")) ||
      (roles && roles.includes("dh-admin")) ||
      (roles && roles.includes("dh-user"))) ? Promise.resolve() : Promise.reject();
    }
    if (localStorage.getItem("app")) {
      const app = JSON.parse(
        CryptoJS.AES.decrypt(
          localStorage.getItem("app"),
          process.env.REACT_APP_APPLICATION_ID
        ).toString(CryptoJS.enc.Utf8)
      );
      const expires = moment.utc(app.expires).valueOf();
      return (app.ssoComplete &&
        expires > moment.utc(Date.now()).valueOf() &&
        app.roles &&
        app.roles.includes("ar-user")) ||
        (app.roles && app.roles.includes("aviary-admin")) ||
        (app.roles && app.roles.includes("dh-admin")) ||
        (app.roles && app.roles.includes("dh-user")) ||
        (app.roles && app.roles.includes("brs-user"))
        ? Promise.resolve()
        : Promise.reject();
    }
    return Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    return Promise.resolve();
  },
};
