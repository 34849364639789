import {Card} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import uniqBy from "lodash/uniqBy";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Button,
  Create,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useCreate,
  useDataProvider,
  useNotify,
  useRedirect
} from "react-admin";
import {useForm } from "react-final-form";
import {DestinationListInput, NestedArrayInput, TrafficInstructionInput} from "../../components";
import {CustomToolbar as BaseCustomToolbar} from "../../components/CustomToolBar";
import {
  alphanumeric,
  checkCopyIdIsMasterIsci,
  checkOriginalIsciExists,
  getAssetData,
  triggerBrsWorkflow,
  uploadFile
} from "../../helpers";
import {createUpdateStyle} from "../../style";


const CustomToolbar = (props) => {
  const [create] = useCreate();
  const redirect = useRedirect();
  const notify = useNotify();
  const onSave = async (data) => {
    if (data.destinations_values.length === 0){
      notify(`We are unable to submit this job please add a destination`, "warning");
      return
    }
    try {
      // Create a Job object
      await create(
          "job",
          {
            state: "draft",
            pending: false,
            waiting: false,
            job_type: "brs",
          },
          {
            onSuccess: async ({data: responseData}) => {
              notify("Job Created Successfully", "info");
              let { filepath} = data
              let brs_job = data
              brs_job.estimate_id = data.estimate_id.toString()
              // Link the Job to a new BRSJob
              let newNotification = {
                latest: true,
                status:"Pending",
                progress:0,
                traffic_instruction_version:"N/A"}
              let BrsJobData = {
                  ...brs_job,
                  job_id: responseData.id,
                  step_status: "PREFLIGHT_IN_PROGRESS",
                  step_history: { "PREFLIGHT":"IN_PROGRESS"},
                  destinations: [...data.destinations_values.map((destination_id) => ({ destination_id,notifications:{data:newNotification} }))],
                  original_iscis: data.original_iscis || [],
                  trafficinstructions:[]
              }
              if (filepath){
                newNotification.traffic_instruction_version = "1"
                notify("Uploading Traffic Instructions", "info");
                const fileName =  filepath.rawFile.name;
                const trafficInstructionUploadResponse = await uploadFile(
                    `${window.REACT_APP_API_URL}/upload-traffic-instructions`,
                    filepath,
                );
                notify("Traffic Instructions Uploaded Successfully", "info");
                BrsJobData = {...BrsJobData,
                  trafficinstructions: {
                    filepath: trafficInstructionUploadResponse.key,
                    name: fileName,
                    version: "1",
                  },
                  destinations: [...data.destinations_values.map((destination_id) => ({ destination_id,notifications:{data:newNotification} }))],
                }
              }
              await create(
                  "brsjob",
                  {...BrsJobData},
                  {
                    onSuccess: async ({data}) => {
                      await triggerBrsWorkflow(data.id);
                      notify("BRS Job Created Successfully", "info");
                      redirect("/brs");
                    },
                    onFailure: (error) => {
                      notify(`${error}`, "warning");
                      redirect(false);
                    }
                  }
              );
            },
            onFailure: (error) => {
              notify(`${error}`, "warning");
              redirect(false);
            }
          }
      );
    } catch (error) {
      notify(`${error}`, "warning");
      redirect(false);
    }
  }

  return (
      <BaseCustomToolbar {...props} saveButtonExtraProps={{...props.saveButtonExtraProps || {}, onSave}}/>
  )
};

const EstimateIdInput = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const [estimateId, setEstimateId] = useState('');

  const handleEstimateIdChange = async (event) => {
    const newEstimateId = event.target.value;
    setEstimateId(newEstimateId);

    await dataProvider.getList(
        "brsjob",
        {
          filter: {
            "estimate_id@_eq": newEstimateId,
            "destinations#id@_is_null": false,
          },
          sort: {
            field: "created",
            order: "DESC",
          },
          pagination: {
            page: 1,
            perPage: 1,
          },
        },
    ).then(({data})=>{
      if(data.length > 0){
        dataProvider.getManyReference('brsjobdestination', {
          target: 'brs_job_id',
          id: data[0].id,
          sort: { field: 'created', order: 'DESC' }
        }).then( ({data})=>  {
          form.change("destinations", data);
          form.change("destinations_values", data.map(d=>d.destination.id));
        })
      }

    } );
  };

  return (
      <TextInput inputProps={{ maxLength: 7 }} {...props} value={estimateId} onChange={handleEstimateIdChange}/>
  );
};

const updateBRSFields = ({value, form}) => {
  if (value === 'FLUIDITY') {
    if (!form.getFieldState('request_id').touched || !form.getFieldState('request_id').value) {
      form.change('request_id', '0');
    }
    if (!form.getFieldState('estimate_id').touched || !form.getFieldState('estimate_id').value) {
      form.change('estimate_id', '0');
    }
    form.getFieldState('broadcastrequests').value.forEach(async (state, index) => {
      if (!form.getFieldState(`broadcastrequests[${index}].sigma_encoding`).touched) {
        form.change(`broadcastrequests[${index}].sigma_encoding`, 'NONE');
      }
      if (!form.getFieldState(`broadcastrequests[${index}].bvs_encoding`).touched) {
        form.change(`broadcastrequests[${index}].bvs_encoding`, 'NO');
      }
      if (!form.getFieldState(`broadcastrequests[${index}].include_sd`).touched) {
        form.change(`broadcastrequests[${index}].include_sd`, false);
      }
    });
  } else {
    form.getFieldState('broadcastrequests').value.forEach((state, index) => {
      if (!form.getFieldState(`broadcastrequests[${index}].sigma_encoding`).touched) {
        form.change(`broadcastrequests[${index}].sigma_encoding`, 'CADENT');
      }
      if (!form.getFieldState(`broadcastrequests[${index}].bvs_encoding`).touched) {
        form.change(`broadcastrequests[${index}].bvs_encoding`, 'NO');
      }
      if (!form.getFieldState(`broadcastrequests[${index}].include_sd`).touched) {
        form.change(`broadcastrequests[${index}].include_sd`, true);
      }
    });
  }
}


const SlateTypeInput = ({source, record = {}, ...rest}) => {
  const form = useForm();

  const handleSlateTypeChange = (event) => {
    const {value} = event.target;
    updateBRSFields({value, form});
  }

  return (
      <SelectInput
          source={source}
          record={record}
          {...rest}
          onChange={handleSlateTypeChange}
      />
  );
}

const getTotalDurationValue = (totalDurationObj) => {
  return Object.values(totalDurationObj).reduce((acc, curr) => {
    if (Number.isNaN(Number(curr))) {
      return acc;
    }
    return Number(acc) + Number(curr);
  }, 0);
}

const OriginalIsciInput = ({source, record = {}, setValidatingOriginalIsci, getSource, disabled, ...rest}) => {
  const form = useForm();
  const [originalIsciValid, setOriginalIsciValid] = useState({});
  const [totalDuration, setTotalDuration] = useState({});
  const [validatingIsci, setValidatingIsci] = useState({index:null, value:false});

  let totalDurationValue;

  if (!disabled) {
    totalDurationValue = getTotalDurationValue(totalDuration);
  } else {
    totalDurationValue = form.getFieldState(getSource("duration")) && form.getFieldState(getSource("duration")).value;
  }

  const handleOriginalIsciBlur = useCallback((index, items, setItems) => async (event) => {
    let {value} = event.target;
    const isci1 = value.endsWith("H") ? value : value + "H";
    const isci2 = value.endsWith("H") ? value.slice(0, -1) : value;
    const includeSDValue = form.getFieldState(getSource("include_sd")).value;
    const args = includeSDValue ? [isci1, isci2] : [isci1];

    if (value) {
      if (!value.endsWith('H')) {
        value = value + 'H';
        items[index] = value;
        setItems(items);
        form.change(source, items);
      }
      setValidatingIsci({index,value:true})
      setValidatingOriginalIsci(true);
      const originalIsciExists = await checkOriginalIsciExists(...args);
      if (originalIsciExists) {
        const assetData = await getAssetData(value) || {};
        if (assetData.duration && !Number.isNaN(assetData.duration)) {
          const totalDurationObj = {...totalDuration, [`${source}.${index}`]: assetData.duration};
          setTotalDuration(totalDurationObj);
          if (!form.getFieldState(getSource("brand")).value) {
            form.change(getSource("brand"), assetData.brand  || 'N/A');
          }
          if (!form.getFieldState(getSource("title")).value) {
            form.change(getSource("title"), assetData.title || 'N/A');
          }
          if (!disabled) {
            if (form.getFieldState(getSource("duration"))) {
              form.change(getSource("duration"), getTotalDurationValue(totalDurationObj));
            }
          }
        }
      }
      setOriginalIsciValid({...originalIsciValid, [`${source}.${index}`]: originalIsciExists});
      setValidatingOriginalIsci(false);
      setValidatingIsci({index,value:false})
    } else {
      setOriginalIsciValid({...originalIsciValid, [`${source}.${index}`]: undefined});
      setTotalDuration({...totalDuration, [`${source}.${index}`]: undefined});
    }
  }, [disabled, form, getSource, originalIsciValid, setValidatingOriginalIsci, source, totalDuration]);

  const validateOriginalIsci = useCallback((index) => (value) => {
    if (originalIsciValid[`${source}.${index}`] === true) {
      return undefined;
    } else if (originalIsciValid[`${source}.${index}`] === false) {
      return "Original ISCI not found";
    } else if (!disabled && !value) {
      return "Required";
    }
    return undefined;
  }, [disabled, originalIsciValid, source]);

  return (
      <NestedArrayInput
          source={source}
          record={record}
          nestedLabel="Original ISCI"
          onBlur={handleOriginalIsciBlur}
          validate={[alphanumeric]}
          nestedValidate={[validateOriginalIsci]}
          validatingIsci={validatingIsci}
          disabled={disabled}
          bottomComponent={
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: "0.5rem"}}>
              <span>Total Duration: {totalDurationValue}</span>
            </div>
          }
          defaultValue={['']}
          onRemove={(index) => {
            let newTotalDuration = {...totalDuration};
            delete newTotalDuration[`${source}.${index}`];
            let newIndex = 0;
            const updatedTotalDuration = {};
            for (const key in newTotalDuration) {
              updatedTotalDuration[`${source}.${newIndex}`] = newTotalDuration[key];
              newIndex++;
            }
            setTotalDuration(updatedTotalDuration);
          }}
          {...rest}
      />
  );
}

const BrandAutocompleteInput = (props) => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const defaultChoices = useMemo(() => [{brand: "N/A"}], []);
  const [choices, setChoices] = useState(defaultChoices);
  const value = form.getFieldState(props.source) && form.getFieldState(props.source).value;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await dataProvider.getList("broadcastrequest", {
        pagination: {page: 1, perPage: 20},
        filter: searchText ? {"brand@_ilike": searchText} : {},
        sort: {field: "created", order: "DESC"},
      })
      if (response) {
        const newChoices = uniqBy([...response.data, ...defaultChoices], "brand");
        setChoices(newChoices);
      }
      setLoading(false);
    })()
  }, [dataProvider, defaultChoices, searchText]);

  useEffect(() => {
    if (form.getFieldState(props.source)) {
      const brand = form.getFieldState(props.source).value;
      if (brand && !choices.find(choice => choice.brand === brand)) {
        setChoices([...choices, {brand}]);
      }
    }
  }, [choices, form, value, props.source]);


  const onCreate = useCallback((newBrand) => {
    if (!newBrand) return choices;
    const newChoices = [...choices, {brand: newBrand}]
    setChoices(newChoices);
    return {brand: newBrand};
  }, [choices]);

  return (
      <AutocompleteInput
          {...props}
          loading={loading}
          choices={choices}
          onCreate={onCreate}
          onInputChange={e => setSearchText(e.target.value)}
          optionValue="brand"
          optionText="brand"
          resettable
      />
  );
}

const IsciInput = ({
                     setValidatingIsci,
                     originalIscisField,
                     durationField,
                     setOriginalIscisDisabled,
                     originalIscisDisabled,
                     ...props
                   }) => {
  const form = useForm();
  const [isci, setIsci] = useState('');
  const [validating, setValidating] = useState(false);
  const [isciExist, setIsciExist] = useState(false);
  const handleIsciBlur = useCallback(async (event) => {
    setIsciExist(false)
    let value = event.target.value;
    if (value) {
      if (!value.endsWith('H')) {
        value = value + 'H';
        setIsci(value);
        form.change(props.source, value)
      }
      setValidatingIsci(true);
      setValidating(true)
      const isMasterIsci = await checkCopyIdIsMasterIsci(value);
      setIsciExist(isMasterIsci)
      setValidating(false)
      setValidatingIsci(false);
      setOriginalIscisDisabled({...originalIscisDisabled, [originalIscisField]: isMasterIsci});
    } else {
      setOriginalIscisDisabled({...originalIscisDisabled, [originalIscisField]: true});
      if (form.getFieldState(originalIscisField) !== undefined) {
        form.change(originalIscisField, undefined);
      }
    }
  }, [durationField, form, originalIscisDisabled, originalIscisField, props.source, setOriginalIscisDisabled, setValidatingIsci]);

  return (
      <TextInput
          {...props}
          onBlur={handleIsciBlur}
          value={isci}
          inputProps={{value: isci}}
          onChange={(event) => setIsci(event.target.value)}
          validate={[()=>{
            if(isciExist){
              return "This ISCI already exists.";
            }
            if(isci.length === 0){
              return "Required";
            }
          }]}
          helperText={validating ? "Checking ISCI..." : ""}
      />
  )
}

export const BrsCreate = (props) => {
  const classes = createUpdateStyle();
  const [validatingIsci, setValidatingIsci] = useState(false);
  const [validatingOriginalIsci, setValidatingOriginalIsci] = useState(false);
  const [originalIscisDisabled, setOriginalIscisDisabled] = useState({});
  const [includeSD, setIncludeSD] = useState({});

  const handleIncludeSDChange = useCallback((field) => async (value) => {
    setIncludeSD({...includeSD, [field]: value});
  }, [includeSD]);

  return (
      <div className={classes.card} style={{width: "100%"}}>
        <a className={classes.breadcrumbLink} href="#/brs">
          {"< BRS Jobs"}
        </a>
        <Create title="Add BRS Job" resource="brsjob" basePath={"/brs"} {...props}>
          <SimpleForm
              redirect="list"
              toolbar={<CustomToolbar {...props} saveButtonExtraProps={{disabled: validatingIsci || validatingOriginalIsci}}/>}
              validateOnBlur={true}
          >
            <Typography variant="h6">New Request</Typography>
            <Card style={{display: "flex", width: "100%"}}>
              <TextInput
                  validate={[required()]}
                  className={classes.textField}
                  source="request_id"
                  label="Request ID"
                  style={{marginRight: "2rem", width: "25%"}}
              />
              <EstimateIdInput
                  validate={[required()]}
                  className={classes.textField}
                  source="estimate_id" label="Estimate ID"
                  style={{marginRight: "2rem", width: "25%"}}
              />
              <SlateTypeInput
                  source="slate_type"
                  validate={[required()]}
                  choices={[
                    {id: "CADENT", name: "Cadent"},
                    {id: "CROSS", name: "Cross"},
                    {id: "FLUIDITY", name: "Fluidity"},
                  ]}
                  className={classes.textField}
                  defaultValue="CADENT"
                  style={{marginRight: "2rem", width: "25%"}}
              />
              <SelectInput
                  source="market_type"
                  validate={[required()]}
                  choices={[
                    {id: "LOCAL", name: "Local"},
                    {id: "NATIONAL", name: "National"},
                  ]}
                  className={classes.textField}
                  defaultValue="LOCAL"
                  style={{width: "25%"}}
              />
            </Card>
            <Card style={{width: "98.6%", padding: 10}} variant="outlined">
              <ArrayInput
                  source="broadcastrequests"
                  initialValue={[{}]}
                  label=""
                  validate={[required()]}
              >
                <SimpleFormIterator
                    disableReordering
                    variant="filled"
                    addButton={
                      <Button label="ra.action.add" style={{color: "#3CE7EA"}}>
                        <AddIcon/>
                      </Button>
                    }
                    removeButton={
                      <Button label="ra.action.remove" style={{color: "#e57373"}}>
                        <CloseIcon/>
                      </Button>
                    }
                >
                  <FormDataConsumer>
                    {({getSource, scopedFormData}) => {
                      return (
                          <>
                            <div style={{display: "flex", width: "100%"}}>
                              <div style={{marginRight: "2rem", width: "25%"}}>
                                <BrandAutocompleteInput
                                    className={classes.textField}
                                    validate={[required()]}
                                    source={getSource('brand')}
                                    record={scopedFormData}
                                    label="Brand"
                                />
                              </div>
                              <TextInput
                                  className={classes.textField}
                                  validate={[required()]}
                                  source={getSource('title')}
                                  record={scopedFormData}
                                  label="Title"
                                  style={{marginRight: "2rem", width: "25%"}}
                              />
                              <NumberInput
                                  className={classes.textField}
                                  style={{display: "none"}}
                                  source={getSource('duration')}
                                  record={scopedFormData}
                                  helperText={false}
                                  initialValue={0}
                              />
                              <IsciInput
                                  className={classes.textField}
                                  validate={[required(), alphanumeric]}
                                  source={getSource('isci')}
                                  record={scopedFormData}
                                  label="ISCI"
                                  style={{marginRight: "2rem", width: "25%"}}
                                  setValidatingIsci={setValidatingIsci}
                                  setOriginalIscisDisabled={setOriginalIscisDisabled}
                                  originalIscisDisabled={originalIscisDisabled}
                                  originalIscisField={getSource('original_iscis')}
                                  durationField={getSource('duration')}
                              />
                              <BooleanInput
                                  defaultValue={true}
                                  className={classes.switch}
                                  source={getSource('include_sd')}
                                  record={scopedFormData}
                                  validate={[required()]}
                                  onChange={handleIncludeSDChange(getSource('include_sd'))}
                                  label="Include SD"
                                  style={{marginRight: "2rem", width: "25%"}}
                              />
                            </div>
                            <div style={{display: "flex", width: "96%"}}>
                              <OriginalIsciInput
                                  source={getSource('original_iscis')}
                                  nestedLabel="Original ISCI"
                                  maxLength={4}
                                  disabled={originalIscisDisabled.hasOwnProperty(getSource('original_iscis')) ? originalIscisDisabled[getSource('original_iscis')] : true}
                                  setValidatingOriginalIsci={setValidatingOriginalIsci}
                                  getSource={getSource}
                              />
                              <SelectInput
                                  source={getSource('sigma_encoding')}
                                  record={scopedFormData}
                                  choices={[
                                    {id: "NONE", name: "None"},
                                    {id: "CADENT", name: "Cadent"},
                                    {id: "TTWN", name: "TTWN"},
                                  ]}
                                  defaultValue="CADENT"
                                  label="Sigma Encoding"
                                  className={classes.textField}
                                  style={{marginRight: "2rem", width: "25%"}}
                                  validate={[required()]}
                              />
                              <SelectInput
                                  choices={[
                                    {id: "YES", name: "Yes"},
                                    {id: "NO", name: "No"},
                                  ]}
                                  defaultValue="NO"
                                  label="BVS Encoding"
                                  className={classes.textField}
                                  style={{marginRight: "2rem", width: "25%"}}
                                  validate={[required()]}
                                  source={getSource('bvs_encoding')}
                                  record={scopedFormData}
                              />
                            </div>
                          </>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Card>
            <Card style={{display: "flex", width: "100%", margin: "10px 0"}}>
              <DestinationListInput
                  brsDestinations={[]}
                  style={{width: '50%'}}
                  source="destinations"
                  resource="destination"
                  sort={{field: "call_letters", order: "ASC"}}
                  canDelete={true}
                  isCreate={true}
              />
              <TrafficInstructionInput source="trafficinstructions" style={{width: '50%'}}/>
            </Card>
          </SimpleForm>
        </Create>
      </div>
  );
};
